import LinkItem from '~@Atoms/link-item/link-item'
import React from 'react'
import { IBlockPaginationNavList } from '~@Types/components/IBlockPagination'

const BlockPaginationNavList = ({
  current,
  pages,
  click,
}: IBlockPaginationNavList) => {
  const currentIndex = pages.findIndex(
    (page) => page.children === current.toString()
  )
  let startIndex = Math.max(0, currentIndex - 2)
  let endIndex = Math.min(pages.length, currentIndex + 3)

  if (endIndex - startIndex < 5) {
    if (startIndex === 0) {
      endIndex = Math.min(pages.length, startIndex + 5)
    } else if (endIndex === pages.length) {
      startIndex = Math.max(0, endIndex - 5)
    }
  }

  const displayedPages = pages.slice(startIndex, endIndex)

  return displayedPages.map((page, index) => {
    const itemClass = `BlockPagination-link flex justify-center items-center h-[47px] w-[47px] rounded-full transition-colors ${
      page?.current
        ? 'border-[1px] border-grey-semi bg-grey-semi cursor-default'
        : 'hover:bg-grey-semi'
    }`
    return (
      <li
        key={index}
        className={`BlockPagination-nav ${!page?.current ? 'hidden desktop:block' : ''}`}
      >
        <LinkItem
          className={itemClass}
          {...page}
          onClick={() => {
            click({ page: Number(page?.children) })
          }}
        />
      </li>
    )
  })
}

export default BlockPaginationNavList
