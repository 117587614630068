import React from 'react'
import LinkItem from '~@Components/atoms/link-item/link-item'
import {
  CaretRight,
  CaretLeft,
  CaretDoubleRight,
  CaretDoubleLeft,
} from '@phosphor-icons/react'
import { IBlockPaginationUi } from '~@Types/components/IBlockPagination'
import BlockPaginationNavList from '~@Molecules/block-pagination/block-pagination-nav-list'

export default function BlockPaginationUI({
  firstPage,
  lastPage,
  prevLink,
  nextLink,
  currentPage,
  pages,
  click,
}: IBlockPaginationUi) {
  const navLabelItemClass =
    'BlockPagination-quickNavLabel group flex gap-xs items-center'
  const navPageItemClass =
    'BlockPagination-quickNavLabel group transition-all hover:bg-grey-semi flex gap-xs items-center px-sm py-xs border-[1px] border-grey-semi rounded-full'
  const navItemClass = 'BlockPagination-quickNav'
  const current: number = currentPage
  return (
    <nav
      role="navigation"
      aria-label="pagination"
      className="BlockPagination my-2xl"
    >
      <ul className="BlockPagination-list flex justify-center items-center gap-md typeface-button1-standard text-black-black">
        {firstPage && (
          <li className={navItemClass}>
            <LinkItem
              {...firstPage}
              className={navPageItemClass}
              onClick={() => click({ page: 1 })}
            >
              <CaretDoubleLeft
                size={24}
                aria-hidden="true"
                className="transition-transform group-hover:-translate-x-3xs"
              />
              <span className="sr-only">{firstPage.children}</span>
            </LinkItem>
          </li>
        )}
        {prevLink && (
          <li className={navItemClass}>
            <LinkItem
              {...prevLink}
              className={navLabelItemClass}
              onClick={() => {
                click({ direction: 'prev' })
              }}
            >
              <CaretLeft
                size={24}
                aria-hidden="true"
                className="transition-transform group-hover:-translate-x-2xs"
              />
              {prevLink.children}
            </LinkItem>
          </li>
        )}
        {current > 3 && (
          <li className="BlockPagination-ellipsis hidden desktop:block">
            <span className="flex justify-center items-center h-[47px] w-[47px] text-black-40">
              ...
            </span>
          </li>
        )}
        <BlockPaginationNavList pages={pages} current={current} click={click} />
        {current < pages.length - 2 && (
          <li className="BlockPagination-ellipsis hidden desktop:block">
            <span className="flex justify-center items-center h-[47px] w-[47px] text-black-40">
              ...
            </span>
          </li>
        )}
        {nextLink && (
          <li className={navItemClass}>
            <LinkItem
              {...nextLink}
              className={navLabelItemClass}
              onClick={() => {
                click({ direction: 'next' })
              }}
            >
              {nextLink.children}
              <CaretRight
                size={24}
                aria-hidden="true"
                className="transition-transform group-hover:translate-x-2xs"
              />
            </LinkItem>
          </li>
        )}
        {lastPage && (
          <li className={navItemClass}>
            <LinkItem
              {...lastPage}
              className={navPageItemClass}
              onClick={() => click({ page: pages.length })}
            >
              <span className="sr-only">{lastPage.children}</span>
              <CaretDoubleRight
                size={24}
                aria-hidden="true"
                className="transition-transform group-hover:translate-x-3xs"
              />
            </LinkItem>
          </li>
        )}
      </ul>
    </nav>
  )
}
