import { useCallback, useEffect, useRef, useState } from 'react'

import {
  UseHorizontalScrollProps,
  UseHorizontalScrollReturn,
} from '~@Types/components/IHoriztontalScroll'

export const useHorizontalScroll = ({
  scrollAmount = 200,
}: UseHorizontalScrollProps = {}): UseHorizontalScrollReturn => {
  const containerRef = useRef<HTMLElement>(null)
  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(false)

  const updateArrowsVisibility = useCallback(() => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current
      setShowLeftArrow(scrollLeft > 0)
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth)
    }
  }, [])

  const scrollTo = useCallback(
    (direction: 'left' | 'right') => {
      if (containerRef.current) {
        const amount = direction === 'left' ? -scrollAmount : scrollAmount
        containerRef.current.scrollBy({ left: amount, behavior: 'smooth' })
      }
    },
    [scrollAmount]
  )

  useEffect(() => {
    updateArrowsVisibility()
    const handleResize = () => updateArrowsVisibility()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [updateArrowsVisibility])

  useEffect(() => {
    const currentRef = containerRef.current
    if (currentRef) {
      currentRef.addEventListener('scroll', updateArrowsVisibility)
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', updateArrowsVisibility)
      }
    }
  }, [updateArrowsVisibility])

  return {
    containerRef,
    showLeftArrow,
    showRightArrow,
    scrollTo,
    updateArrowsVisibility,
  }
}
