'use client'

import { twMerge } from 'tailwind-merge'

import LinkItem from '~@Atoms/link-item/link-item'

import { IBreadcrumb } from '~@Types/components/IBreadcrumb'
import { ILinkProps } from '~@Types/components/ILinkProps'

import { CaretLeft, CaretRight } from '@phosphor-icons/react'

import { useHorizontalScroll } from '~@Hooks/useHorizontalScroll'

const Breadcrumb = ({
  className,
  ariaLabel,
  ariaHidden,
  ariaLabelScrollLeft,
  ariaLabelScrollRight,
  items,
}: IBreadcrumb) => {
  const { containerRef, showLeftArrow, showRightArrow, scrollTo } =
    useHorizontalScroll()

  const list = items?.map((item: ILinkProps, index: number) => {
    return (
      <li
        key={index}
        className={`Breadcrumb-item whitespace-nowrap	flex items-center gap-md text-grey-dark typeface-caption-standard ${index != 0 ? "before:content-[''] before:block before:rounded-full before:bg-grey-grey before:w-xs before:h-xs" : null}`}
      >
        <LinkItem
          className={
            item.tagElt === 'a' || item.tagElt === 'Link'
              ? "relative before:absolute before:content-[''] before:bottom-none before:left-none before:h-4xs before:bg-[currentColor] before:pointer-events-none before:w-none before:transition-[width] before:duration-900 before:ease-[cubic-bezier(.135,.9,.15,1)] hover:before:w-full"
              : ''
          }
          {...item}
          ariaLabel={
            item.ariaLabel || item.children?.toString() || 'Breadcrumb link'
          }
        />
      </li>
    )
  })

  return (
    <nav
      role="navigation"
      aria-label={ariaLabel ? ariaLabel : ''}
      className={twMerge('Breadcrumb relative', className)}
      {...(ariaHidden ? { 'aria-hidden': ariaHidden } : {})}
    >
      {showLeftArrow && (
        <button
          onClick={() => scrollTo('left')}
          aria-label={ariaLabelScrollLeft || 'Scroll left'}
          className="absolute left-none top-1/2 -translate-y-1/2 p-xs rounded-full hover:bg-grey-light z-10"
          tabIndex={0}
        >
          <CaretLeft />
        </button>
      )}
      <ul
        ref={containerRef as React.RefObject<HTMLUListElement | null>}
        className={`Breadcrumb-list flex items-center justify-start gap-md overflow-x-auto scrollbar-hide
          ${showLeftArrow ? '[mask-image:linear-gradient(to_right,transparent,black_5%,black_95%,transparent)]' : ''}
          ${showRightArrow ? '[-webkit-mask-image:linear-gradient(to_right,transparent,black_5%,black_95%,transparent)]' : ''}`}
      >
        {list}
      </ul>
      {showRightArrow && (
        <button
          onClick={() => scrollTo('right')}
          aria-label={ariaLabelScrollRight || 'Scroll right'}
          className="absolute right-none top-1/2 -translate-y-1/2 p-xs rounded-full hover:bg-grey-light z-10"
          tabIndex={0}
        >
          <CaretRight />
        </button>
      )}
    </nav>
  )
}

export default Breadcrumb
